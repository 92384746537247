var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Contextual states" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeStates) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Bootstrap includes validation styles for ")]),
        _c("code", [_vm._v("valid")]),
        _c("span", [_vm._v(" and ")]),
        _c("code", [_vm._v("invalid")]),
        _c("span", [_vm._v(" states on most form controls.")])
      ]),
      _c("b-form-textarea", {
        attrs: {
          id: "textarea-state",
          state: _vm.text.length <= 10,
          placeholder: "Enter only 10 characters",
          rows: "3"
        },
        model: {
          value: _vm.text,
          callback: function($$v) {
            _vm.text = $$v
          },
          expression: "text"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }